import EXTERNAL_LINKS from 'temp/externalLinks.json'
import SocialButton from 'components/SocialButton'
import classes from './styles.module.scss'
import useGetGAEventMenuParams from 'containers/Menu/utilityHook'

export default function SocialButtons() {
  const { GAEventMenuParams } = useGetGAEventMenuParams()

  return (
    <div className={classes.root}>
      {EXTERNAL_LINKS.fb && (
        <SocialButton
          type='facebook'
          href={EXTERNAL_LINKS.fb}
          eventName='clicked_on_facebook_menu'
          GAEventMenuParams={GAEventMenuParams}
        />
      )}
      {EXTERNAL_LINKS.instagram && (
        <SocialButton
          type='instagram'
          href={EXTERNAL_LINKS.instagram}
          eventName='clicked_on_instagram_menu'
          GAEventMenuParams={GAEventMenuParams}
        />
      )}
      {EXTERNAL_LINKS.twitter && (
        <SocialButton
          type='twitter'
          href={EXTERNAL_LINKS.twitter}
          eventName='clicked_on_twitter_menu'
          GAEventMenuParams={GAEventMenuParams}
        />
      )}
      {EXTERNAL_LINKS.tiktok && <SocialButton type='tiktok' href={EXTERNAL_LINKS.tiktok} />}
    </div>
  )
}
