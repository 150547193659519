import { Link } from 'components/Link'
import cx from 'classnames'
import { useTranslation } from 'context/translationContext'
import LogoLink from 'containers/LogoLink'
import SocialButtons from 'containers/Menu/SocialButtons'
import RichText from 'components/RichText'
import ShopTerms from 'components/ShopTerms'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { useCountryConfig } from 'app/context/country'
import { useAuth } from 'app/context/auth'
import { SupportBlock } from './components/SupportBlock'

function Footer({ isFooterCentered, className }: { isFooterCentered?: boolean; className?: string }) {
  const t = useTranslation()
  const { footerGamesMenuFeature, socialLinksFeature } = useCountryConfig()
  const { isScEnabled } = useAuth()

  return (
    <footer className={cx(classes.root, { [classes.centered]: isFooterCentered }, className)}>
      <div className={classes.footerLogoHolder}>
        <LogoLink className={classes.logo} />
        <p className={classes.text}>{t('footer.copyright')}</p>
      </div>
      <ShopTerms sweepstakeEnabled={isScEnabled} />
      <div className={classes.footerMenuHolder}>
        <div className={classes.fmBlock}>
          <h4 className={classes.footerTitle}>{t('footer.about_menu_title')}</h4>
          <ul className={classes.footerMenu}>
            <li className={classes.fmItem}>
              <Link href={ROUTES.ABOUT_PAGE} data-test='footer-about-link'>
                {t('footer.about')}
              </Link>
            </li>
            {isScEnabled && process.env.ZENDESK_SUPPORT_LINK ? (
              <li className={classes.fmItem}>
                <a
                  href={process.env.ZENDESK_SUPPORT_LINK}
                  data-test='footer-contact-us-link'
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('footer.customer_support')}
                </a>
              </li>
            ) : null}
            <li className={classes.fmItem}>
              <Link href={ROUTES.TERMS} data-test='footer-terms-link'>
                {t('footer.terms_of_service')}
              </Link>
            </li>
            {isScEnabled && (
              <li className={classes.fmItem}>
                <Link href={ROUTES.SWEEPSTAKE_RULES} data-test='footer-sc-rules-link'>
                  {t('footer.sweepstakes_rules')}
                </Link>
              </li>
            )}
            <li className={classes.fmItem}>
              <Link href={ROUTES.RESPONSIBLE_PLAY} data-test='footer-responsible-play-link'>
                {t('footer.responsible_play')}
              </Link>
            </li>

            <li className={classes.fmItem}>
              <Link href={ROUTES.PRIVACY} data-test='footer-privacy-link'>
                {t('footer.privacy_policy')}
              </Link>
            </li>

            <li className={classes.fmItem}>
              <RichText field={t('footer.blog')} />
            </li>
          </ul>
        </div>
        <div className={classes.fmBlock}>
          <RichText
            field={t(footerGamesMenuFeature.enabled ? 'footer.games_menu_us' : 'footer.games_menu_non_us')}
            components={{
              heading4: ({ children }) => <h4 className={classes.footerTitle}>{children}</h4>,
              list: ({ children }) => <ul className={classes.footerMenu}>{children}</ul>,
              listItem: ({ children }) => <li className={classes.fmItem}>{children}</li>,
            }}
          />
        </div>
      </div>
      {socialLinksFeature.enabled && (
        <>
          <div className={classes.footerMenuHolder}>
            <div className={classes.fmBlock}>
              <h4 className={classes.footerTitle}>{t('footer.follow_us')}</h4>
              <SocialButtons />
            </div>
          </div>
          <div className={classes.divider} />
          <SupportBlock />
        </>
      )}
      <div className={classes.divider} />
      <div className={classes.textBlock}>
        <p className={classes.text}>{t('footer.owned_text')}</p>
        <p className={classes.text}>{t('footer.copyright')}</p>
      </div>
    </footer>
  )
}

export default Footer
