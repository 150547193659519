import { Link } from 'components/Link'
import cx from 'classnames'
import { useTranslation } from 'context/translationContext'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'

type Props = {
  openSweepRules?: () => void
  sweepstakeEnabled?: boolean
  className?: string
}

export default function ShopTerms(props: Props) {
  const { openSweepRules, sweepstakeEnabled, className } = props
  const t = useTranslation()

  return (
    <p className={cx('mt-typography-body2', classes.description, className)}>
      {t('common.at_word')}{' '}
      <Link href={process.env.ROOT_URL} passHref>
        {t('common.brand_name')}
      </Link>{' '}
      {t(sweepstakeEnabled ? 'shop.shop_terms_sc' : 'shop.shop_terms_gc')}{' '}
      {sweepstakeEnabled && t('shop.find_sweep_rules')}{' '}
      {sweepstakeEnabled && (
        <>
          <Link href={ROUTES.SWEEPSTAKE_RULES} onClick={openSweepRules}>
            {t('common.sweepRules')}
          </Link>
          .{' '}
        </>
      )}
      <Link href={ROUTES.TERMS} onClick={openSweepRules}>
        {t('common.term_conditions')}
      </Link>{' '}
      {t('common.apply')}
    </p>
  )
}
