import cx from 'classnames'
import classes from './styles.module.scss'

import type { GAEventMenuTypes } from 'containers/Menu/types'
import { trackEvent } from 'config/analytic'

type IconTypes = 'facebook' | 'twitter' | 'instagram' | 'tiktok'

type Props = {
  type: IconTypes
  href: string
  className?: string
  eventName?: string
  GAEventMenuParams?: GAEventMenuTypes
}

export default function SocialButton(props: Props) {
  const onClickHandler = () => {
    trackEvent(props.eventName, {
      category: props.GAEventMenuParams.category,
      label: props.GAEventMenuParams.label,
    })
  }

  return (
    <a
      href={props.href}
      className={cx(classes.root, props?.className)}
      rel='noopener noreferrer'
      target='_blank'
      data-test={`social-button-${props.type}`}
      {...(props.eventName && { onClick: onClickHandler })}
    >
      <img
        className={classes.image}
        src={`${process.env.IMG_ORIGIN}/logos/${props.type}_logo.png`}
        alt={props.type}
        loading='lazy'
      />
    </a>
  )
}
