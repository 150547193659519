import { useCountryConfig } from 'app/context/country'
import RichText from 'components/RichText'
import { useTranslation } from 'app/context/translations'
import classes from '../../styles.module.scss'

export function SupportBlock() {
  const t = useTranslation()
  const { paymentSupportNumbersPrimaryFeature, paymentSupportNumbersSecondaryFeature } = useCountryConfig()

  return (
    <div className={classes.textBlock}>
      <h4 className={classes.footerTitle}>
        {t('footer.online_support')}{' '}
        <a href={process.env.ZENDESK_SUPPORT_LINK} target='_blank' rel='noreferrer'>
          {t('footer.contact_us')}
        </a>
      </h4>

      <div className={classes.telBlock}>
        {paymentSupportNumbersPrimaryFeature.enabled && (
          <div className={classes.telWrapper}>
            <p className={classes.text}>{t(paymentSupportNumbersPrimaryFeature.label)}</p>
            <RichText
              field={t(paymentSupportNumbersPrimaryFeature.numbers)}
              components={{
                paragraph: ({ children }) => <p className={classes.text}>{children}</p>,
              }}
            />
          </div>
        )}
        {paymentSupportNumbersSecondaryFeature.enabled && (
          <div className={classes.telWrapper}>
            <p className={classes.text}>{t(paymentSupportNumbersSecondaryFeature.label)}</p>
            <RichText
              field={t(paymentSupportNumbersSecondaryFeature.numbers)}
              components={{
                paragraph: ({ children }) => <p className={classes.text}>{children}</p>,
              }}
            />
          </div>
        )}
      </div>
      <p className={classes.text}>{t('footer.tel_caption')}</p>
      <RichText
        field={t('footer.partners_link')}
        components={{
          paragraph: ({ children }) => <p className={classes.text}>{children}</p>,
        }}
      />
    </div>
  )
}
