import { Link } from 'components/Link'
import cx from 'classnames'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'

export type Props = {
  handleOnClick?: () => void
  className?: string
}

export default function LogoLink({ handleOnClick, className, ...props }: Props) {
  return (
    <Link href={ROUTES.LANDING_PAGE} passHref data-test-='logo-link'>
      <img
        src={`${process.env.IMG_ORIGIN}/logo.png`}
        height={32}
        alt={process.env.BRAND_NAME}
        onClick={handleOnClick && handleOnClick}
        className={cx(classes.logo, className)}
        {...props}
      />
    </Link>
  )
}
